import * as React from "react";
import { navigate, Link } from "gatsby";
import { Spinner, Overlay, Tooltip, Form, Button } from "react-bootstrap";
import Tab from "../../components/tab";
import * as _ from "lodash";
import Layout from "../../components/layout";
import SwipeableViews from "react-swipeable-views";
import {
    login,
    isLoggedIn,
    setRedirectTo,
    isBrowser,
    getJWT,
} from "../../services/auth";
import api, { catcher } from "../../services/api";
import axios from "axios";

import googleGLogo from "../../images/Google-G-Logo.svg";

const ComingSoon = ({ children }) => {
    const [show, setShow] = React.useState(false);
    const target = React.useRef(null);

    return (
        <>
            {React.cloneElement(children, {
                ref: target,
                onClick: (event) => {
                    event.preventDefault();
                    setShow(!show);
                },
                onBlur: () => setShow(false),
            })}
            <Overlay target={target.current} show={show} placement="bottom">
                {(props) => (
                    <Tooltip id="overlay-example" {...props}>
                        Coming Soon
                    </Tooltip>
                )}
            </Overlay>
        </>
    );
};

const GoogleLogin = () => (
    <button
        onClick={() => {
            isBrowser() && setRedirectTo(window.location.href);
            login();
        }}
        style={{
            paddingLeft: "8px",
            paddingRight: "8px",
            minHeight: "40px",
            border: "solid 2px #eeeffa",
            fontSize: "14px",
            color: "rgb(0,0,0,.54)",
            textAlign: "center",
        }}
        className="btn btn-light bg-white rounded btn-block"
    >
        <img
            style={{
                marginRight: "24px",
                width: "18px",
            }}
            src={googleGLogo}
        />
        <span>Log in dengan Google</span>
    </button>
);

const LoginCard = () => (
    <div className="card">
        <div className="card-body">
            <h3 className="card-title">Log in untuk melanjutkan</h3>
            <GoogleLogin />
            <hr />
            <Form onSubmit={(e) => e.preventDefault()}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" />
                </Form.Group>
                <Button variant="primary" type="submit" block>
                    Log in
                </Button>
            </Form>
            <ComingSoon>
                <a href="#" className="text-muted small">
                    Punya persoalan tentang login? Klik disini
                </a>
            </ComingSoon>
        </div>
    </div>
);

const TransaksiItem = ({ title, status, value, id, ...props }) => {
    return (
        <Link
            to={"/transaction/detail/?id=" + id}
            className="d-flex mb-3 p-3 border bg-white text-decoration-none text-body"
            {...props}
        >
            <div className="flex-grow-1">
                {title}
                <small className="d-block text-muted">{status}</small>
            </div>
            <span className="text-muted">{value}</span>
        </Link>
    );
};

function TabPanel(props) {
    const {
        children,
        value,
        index,
        ...other
    }: { children: React.ReactNode; value; index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <div className="container p-3">{children}</div>}
        </div>
    );
}

function shorten(str, maxLen, separator = " ") {
    if (str.length <= maxLen) return str;
    return str.substr(0, str.lastIndexOf(separator, maxLen)) + "...";
}

const SV = ({ index, onChange, transactions }) => {
    const handleChangeIndex = (index) => {
        onChange(index);
    };

    return (
        <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
            <TabPanel value={index} index={0}>
                {transactions.status === 200 ? (
                    transactions.data.length > 0 ? (
                        transactions.data
                            .filter(
                                (transaction) => transaction.status !== "done"
                            )
                            .map((transaction) => (
                                <TransaksiItem
                                    title={shorten(
                                        transaction.products.reduce(
                                            (acc, { name }, i) =>
                                                i === 0
                                                    ? acc + name
                                                    : acc + ", " + name,
                                            ""
                                        ),
                                        26
                                    )}
                                    status={
                                        {
                                            pendingPay: "Belum dibayar",
                                            pendingConfirm:
                                                "Menunggu Konfirmasi",
                                        }[transaction.status] ||
                                        transaction.status
                                    }
                                    value={
                                        "Rp" +
                                        (
                                            transaction.products.reduce(
                                                (acc, { price }) => acc + price,
                                                0
                                            ) + transaction.payCodeSuffix
                                        ).toLocaleString("id")
                                    }
                                    id={transaction.id}
                                />
                            ))
                    ) : (
                        <p>Empty</p>
                    )
                ) : (
                    <Spinner
                        animation="border"
                        variant="primary"
                        className="d-block mx-auto"
                        role="status"
                    >
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                )}
            </TabPanel>
            <strong>@TODO</strong>
            <TabPanel value={index} index={1}></TabPanel>
        </SwipeableViews>
    );
};

const Transaksi = () => {
    const [index, setIndex] = React.useState(0);
    const [hasMounted, setHasMounted] = React.useState(false);
    const [transactions, setTransactions] = React.useState({});

    React.useEffect(() => {
        setHasMounted(true);
    }, []);

    React.useEffect(() => {
        if (isLoggedIn())
            api.get("/transactions")
                .then((res) => {
                    setTransactions(res);
                })
                .catch(catcher);
    }, []);

    if (!hasMounted) {
        return <Layout title="Transaksi" bottomNav />;
    }

    if (isLoggedIn()) {
        return (
            <Layout
                title="Transaksi"
                bottomNav
                topNav={<Tab index={index} onChange={(e, i) => setIndex(i)} />}
            >
                <SV
                    index={index}
                    onChange={setIndex}
                    transactions={transactions}
                />
            </Layout>
        );
    }

    return (
        <Layout title="Transaksi" bottomNav>
            <div className="container mt-3">
                <LoginCard />
            </div>
        </Layout>
    );
};

export default Transaksi;
