import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { navigate } from "gatsby";

const cTheme = createTheme({
    palette: {
        primary: {
            main: "#0983fe",
        },
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
});

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        "aria-controls": `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function FullWidthTabs({ index, onChange }) {
    const classes = useStyles();

    return (
        <MuiThemeProvider theme={cTheme}>
            <div className={classes.root}>
                <Tabs
                    value={index}
                    onChange={onChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs"
                >
                    <Tab label="Tagihan" {...a11yProps(0)} />
                    <Tab label="Riwayat" {...a11yProps(1)} />
                </Tabs>
            </div>
        </MuiThemeProvider>
    );
}
